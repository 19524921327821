.author-box {
    width: 100%;
    height: 100%;
    border: solid 1px #dae3ee;
    background-color: #f0f4f9 ;
    padding: 2rem 3rem;
    
    &--icon {
        float: left;
        img {
            width: 50px;
            height: 50px;
            margin: 0 10px 2px 0;
            border-radius: 50%;
            padding: 11px;
            box-shadow: 0 3px 20px 0 rgba(7, 40, 80, 0.05);
            background-image: linear-gradient(225deg, #fff 100%, #ccc 0%);
            margin-right: 30px;
        }
    }
    &--text {
        float: right;
        p:nth-child(1), a:nth-child(1) {
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #43b1e7;
            margin-top: 15px;
        }
        p:nth-child(2),p:nth-child(3), a:nth-child(2),a:nth-child(3) {
            font-family: 'Roboto Slab';
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }
        p:nth-child(2), a:nth-child(2) {
            color: #011c3e;
            font-weight: bold;
        }
        p:nth-child(3), a:nth-child(3) {
            color: #6e7a8a;
            line-height: 1.57;
            margin-bottom: 5px;
        }
    }
}