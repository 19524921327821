.contactpage {
    &-form {
      bottom: 0px;
      padding: 3.46rem 12.5rem;
      background: white;
      h5 {
        color: #43b1e7;
        margin-top: 1.8rem;
        margin-bottom: 1.8rem;
      }
      p {
        font-size: 16px;
        margin-top: 0.5rem;
      }
      label {
        font-size: 14px;
        text-transform: uppercase;
      }
      input,
      textarea,
      button {
        border-radius: 0px;
      }
      input,
      textarea {
        height: 60px;
      }
      button {
        height: 70px;
        width: 175px;
        outline:0;
      }
    }
  }

  input {
    background-color: transparent;
    height: 100%;
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    line-height: 68px;
    color: ccc;
    outline: none;
    z-index: 1;
    &::placeholder {
      opacity: 0.6;
      color: ccc;
    }
    &:focus {
      color: ccc;

      &::placeholder {
        opacity: 1;
      }
    }
    &:valid {
      color: ccc;
    }
    &:invalid {
      color: #fa6400;
      &::placeholder {
        color: #fa6400;
      }
    }
    &:not(:focus) {
      &:invalid {
        &::placeholder {
          color: ccc;
        }
      }
    }
    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }
  }

  .error-label {
    color: #fa6400;
    font-size:11px !important;
    font-weight:400;
  }

  .form-sent {
    position:absolute;
    width:100%;
    height:100%;
    background:#43b1e7;
    max-height:0;
    opacity:0;
    transition: all 200ms ease-in-out;
    &.isSent {
      max-height:1000px;
      opacity:1;
      transition: all 0.25s ease-in;
    }
      &-wrapper{
        position:absolute;
        width:100%;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        text-align: center;
        opacity:0;
        .isSent & {
          opacity:1;
          transition-delay:500ms;
          transition: opacity 0.25s linear;
        }
      }
      &-icon{
        width:64px;
        height:64px;
        display:inline-block;
      }

      &-text{
        font-size:16px;
        text-align:center;
        margin-top:2rem;
        font-weight:bold;
        color: #fff;
      }
  }