.member-detail-page {
  background-repeat: no-repeat;
  background-size: cover;

  .subnavigation-wrapper {
    padding: 3rem 3rem 2rem;
    @media (min-width: 768px) {
      padding: 9.29rem 6rem 4rem;
    }
  }
  &--content {
    width: 100%;
    height: 100%;
    position: relative;
    &_text {

      h1 {
        font-family: "Roboto";
        font-size: 2rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #131a22;
        margin-bottom: 2rem;
      }

      p {
        font-family: "Roboto Slab";
        font-size: 1rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #131a22;

        span {
          margin-right: 10px;
        }
      }
      
      padding: 3rem 3rem 2rem;

      @media (min-width: 768px) {
        padding: 2rem 6rem 4rem;
      }
      @media (min-width: 1280px) {
        padding: 4rem 12rem 8rem;
      }
    }
    &_author {
      display: none;
      position: absolute;
      top: 25%;
      right: -200px;
      height: 200px;

      @media (min-width: 1280px) {
        display: block;
      }
    }
  }

  &--text {
    padding-top: 3.125rem;
    padding-right: 2.6rem;
    padding-bottom: 7.5rem;
    padding-left: 2.6rem;
    bottom: 0px;
    background: white;
  }

  &--author {
    display: none;
    background: #f0f4f9;
    position: absolute;
    top: 20%;
    right: -225px;
    padding: 2.8rem 4.6rem;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0.3rem;
      float: left;
    }
    p {
      text-align: right;
      display: block;
    }
    p:nth-child(2) {
      color: #43b1e7 !important;
    }
    p:nth-child(3) {
      color: #011c3e;
    }
    p:nth-child(n + 4) {
      color: #6e7a8a;
    }
  }
}
