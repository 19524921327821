.carousel {

  height: 100%;
  width: 100%;
  position: relative;
  .owl-falan {
    background-color: red;
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0px;
  }
  .item {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    height: 100vh;
    width: 100%;
    color: white;
    font-size: 50px;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgb(0, 114, 170);
      background: linear-gradient(
        90deg,
        rgba(0, 114, 170, 1) 0%,
        rgba(0, 212, 255, 0.3127626050420168) 100%
      );
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-content {

      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-family: "Roboto";
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        z-index: 99;
        @media (min-width: 768px) {
          font-size: 42px;
        }

      }

      h2 {
        font-family: "Roboto Slab";
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: white;
        font-weight: 200;
        font-family: "Roboto Slab";
        line-height: 21px;
        max-width: 70%;
        margin-top: 1.5rem;
        margin-bottom: 3rem;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 42px;
          max-width: 600px;
        }
      }
    }
  }
  .owl-navigation {
    position: absolute;
    z-index: 23;
    bottom: 0;
    height: 100px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &--controls {
      grid-column-start: 2;
      justify-self: end;

      .owl-prev,
      .owl-next {
        background: #00142e !important;
        width: 120px;
        height: 100% !important;
        margin: 0px !important;
        position: relative;
        border-radius: 0px !important;
        display: inline-block;
        cursor: pointer;
        span {
          display: none;
        }
        &::before {
          content: "";
          position: absolute;
          width: 50px;
          height: 50px;
          left: 50px;
          top: 35px;
          background-repeat: no-repeat;
        }
        &:hover {
          background: #001a3c !important;
        }
      }
      .owl-prev::before {
        background-image: url("../../Assets/Images/slider-controls-left.svg");
      }
      .owl-next::before {
        background-image: url("../../Assets/Images/slider-controls-right.svg");
      }
    }
  }
}
