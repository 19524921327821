.ourteampage {
    .subnavigation-wrapper {
        box-shadow: 0 3px 20px 0 rgba(7, 40, 80, 0.05);
        background-image: linear-gradient(263deg, #0072aa, #011c3e 0%);
        padding: 6.64rem 3.6rem;
        @media (min-width: 768px) {
            padding: 6.64rem 6rem;
        }
    }
    .member-card-wrapper {
        width: 100%;
        height: 100%;
    }
}