@font-face {
    font-family: 'Roboto';
    src: url('subset-Roboto-Thin.woff2') format('woff2'),
        url('subset-Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('subset-Roboto-Light.woff2') format('woff2'),
        url('subset-Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('subset-Roboto-Regular.woff2') format('woff2'),
        url('subset-Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('subset-Roboto-Bold.woff2') format('woff2'),
        url('subset-Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('subset-Roboto-Medium.woff2') format('woff2'),
        url('subset-Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('subset-RobotoSlab-Thin.woff2') format('woff2'),
        url('subset-RobotoSlab-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('subset-RobotoSlab-Regular.woff2') format('woff2'),
        url('subset-RobotoSlab-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('subset-RobotoSlab-Bold.woff2') format('woff2'),
        url('subset-RobotoSlab-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('subset-RobotoSlab-Light.woff2') format('woff2'),
        url('subset-RobotoSlab-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

