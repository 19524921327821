.subnavigation {
    width: 100%;
    min-height: 150px;
    position: relative;
    &-links {
        position: absolute;
        z-index: 88;
        ul {
            width: max-content;
            padding: 0px;
            font-family: 'Roboto';
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #ffffff;
            padding: 15px 0 25px 1px;
            position: relative;
            text-transform: capitalize;
                button { 
                    color:#fff; font-weight: 600;
                    background:transparent;
                    border:0;
                    outline:0;
                    padding:0;
                }
            &:before{
                position: absolute;
                background: #DECBA4;
                background: -webkit-linear-gradient(to right, #ba9a7a, #DECBA4); 
                background: linear-gradient(to right, #ba9a7a, #DECBA4); 
                min-width:320px;
                width:120%;
                height:110%;
                content:"";
                left:50%;
                top:45%;
                transform:translate(-50%, -50%);
                visibility: hidden;
                z-index: -1;
            }
            .icon-wrapper {
                width: 20px;
                height: 2rem;
                margin-left: 1rem;
                display: inline-block;
            }
            img {
                //-webkit-box-shadow: 0px -1px 11px -3px rgba(0,0,0,0.72); 
                //box-shadow: 0px -1px 11px -3px rgba(0,0,0,0.72);
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin-top: -3px;
            }
            li:nth-child(2) {
                border-top: 1px solid rgba(0,0,0,0.05);
                padding-top:2rem;
                margin-top:1rem;
            }
            li {
                display: none;
                font-size: 16px;
                line-height: 1.88;
                padding-top: .6rem;
                padding-bottom:.5rem;
                position: relative;
                a {
                    color: #666;
                    font-weight: normal;
                    &:hover{
                        color:rgba(1, 28, 62, 1)
                    }
                }
            }
            &.show {
                color:#333;
                &:before{
                    visibility: visible;

                }
                li {
                    display: block;
                }
            }
        }
    }
    h1 {
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
        font-family: 'Roboto';
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #ffffff;
        
        position: relative;
        top:4rem;
        
        text-transform: capitalize;

        @media screen and (min-width: 768px) {
            max-width: 80%;
            position: absolute;
            font-size: 42px;
            
        }
        @media screen and (min-width: 1024px) {
            max-width: 60%;
            position: absolute;
            font-size: 42px;
            
        }

    }
}