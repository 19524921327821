.button-rm {
    --primary-color:rgb(67, 177, 231);
    --hovered-color:rgb(67, 177, 231, 0.02);
    --font-size:14px;
        border: 1px solid var(--primary-color);
        padding: .5rem 1rem;
        border-radius: 5px;
        color: var(--primary-color);
        background-color: transparent;
        font-family: 'Roboto';
        font-size: var(--font-size);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;        
        &:hover {
            color:#011C3E;
            border-color:#011C3E;
            background-color:var(--hovered-color);
        }
        &:focus {
            border: 1px solid var(--primary-color) ;
        }
    &--light {
        color:#fff;
        border-color:#fff;

        &:hover {
            color:rgb(67, 177, 231);
            border-color:rgb(67, 177, 231);
        }

        &:focus {
            color:rgb(67, 177, 231);
            border-color:rgb(67, 177, 231);
        }
    }

    &--inverse {
        color: var(--primary-color);
        border-color:var(--primary-color);

        &:hover {
            color:#fff;
        border-color:#fff;

        }

        &:focus {
            color:#fff;
        border-color:#fff;

        }
    }
}