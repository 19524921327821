.counter {
    width: 100%;
    height: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-number {
        font-family: 'Roboto';
        font-size: 96px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        position: relative;
        &:before{
            z-index: 0;
            position: absolute;
            top: -15px;
            left: -60px;
            content: attr(data-symbol);
            width: 10px;
            height: 10px;
            font-size: 60px;
            color: #000;
            font-weight: 100;
            opacity: 0.4;
        }
    }
    &-title {
        font-family: 'Roboto';
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #43B1E7;
        margin:0 10px 10px;
    }
    p {
        font-family: 'Roboto Slab';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ddd;
    }
}