.sample-page {
  overflow: hidden;
  position: relative;

  --article-paddings: 6.64rem 3.6rem;

  @media (min-width: 768px) {
    --article-paddings: 6.64rem 3.6rem;
  }
  @media (min-width: 1024px) {
    --article-paddings: 6.64rem 6rem;
  }
  @media (min-width: 1280px) {
    --article-paddings: 6.64rem 6rem;
  }


  .subnavigation-wrapper {
    padding: var(--article-paddings);
    box-shadow: 0 3px 20px 0 rgba(7, 40, 80, 0.05);
    background-image: linear-gradient(263deg, #0072aa, #011c3e 0%);
    
  }
  .article-wrapper {
    padding: var(--article-paddings);
    position: relative;
    .article {
        /*&--image{
          position: absolute;
          top:0;
          left: 0;
          width:100%;
          img{
            height:auto;
          }
        }
        &--content{
          padding-top:350px;
        }*/
    }
  }
  .column-borders {
    border-right: solid 1px #dae3ee;

      .article-wrapper {
      }
  }
  .column-borders:last-child {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        height: 100%;
        border-right: solid 1px #dae3ee;
        right: calc(-100% + -2px);
    }
  }

}
