.homepage {

  &-carousel-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .carousel {
      .item{
        &-content {
          padding: var(--paddings);
        }
      }
    }
  }
  &-articles {
    height: max-content;
    .bordered-column:nth-child(2) {
      border-left: solid 1px #dae3ee;
      border-right: solid 1px #dae3ee;
    }
    .bordered-column:nth-child(3) {
      border-top: solid 1px #dae3ee;
    }
    .article-wrapper {
      padding: var(--paddings);
      margin: 0 auto;
      height: 100%;
      position: relative;

      h4 {
        color: #43b1e7;
        margin-bottom: 1.8rem;
        font-weight: bold;
      }
    }
    .about-us-article {
      top: -100px;
      background-color: white;
      z-index: 1;
      left: 0;
      padding: 6rem var(--paddings) 6rem var(--paddings);

      @media (min-width: 1200px) {
        position: absolute;
        font-size: 16px;
        padding: var(--paddings);
      }

      h4 {
        color: #43b1e7;
        margin-bottom: 1.8rem;
        font-weight: bold;
      }

      p {
        font-family: "Roboto Slab";
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align:justify;
        color: #131a22;
        @media (min-width: 1280px) {
          font-size: 15px;
        }
        @media (min-width: 1600px) {
          font-size: 16px;
        }
      }
    }
  }
  &-activities {
    position: relative;
    background: #011c3e;

    h3 {
      margin-top: 4rem;
      font-family: "Roboto";
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
    &--activitybox-wrapper {
      width: 100%;
      height: 400px;
      padding: var(--paddings);
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      &:hover {
        box-shadow: 10px 10px 30px #000f23;
      }
    }
    .homepage-activities--see-more {
      text-align: center;
      position: absolute;
      bottom: -20px;
      a {
        font-family: "Roboto";
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #43b1e7;
        text-transform: uppercase;
      }
    }
  }
  &-ourteam {
    background: linear-gradient(90deg, #0072aa 0%, #0072aa 0%, #011c3e 100%);
    min-height: 600px;

    @media (min-width: 768px) {
      height: auto;
    }

    &--box-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      h2 {
        font-family: "Roboto";
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-left: var(--paddings);
      }
      h2 + p {
        font-family: "Roboto Slab";
        font-size: 1rem;
        font-weight: light;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #ddd;
         line-height: 1.6rem;
        margin: 2rem var(--paddings);
        text-align:justify;
      }
      button {
        margin-left: var(--paddings);
      }
    }
  }
  &-announcements {
    height: auto;
    &--left {
      height: 100%;
      width: 100% !important;
      padding: var(--paddings);
      display: flex;
      flex-direction: column;
      
      align-items: center;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      .ann-item{
        position: relative;
        margin-top:4rem;
        @media (min-width: 768px) {
          margin-top:0;
        }
      }
      img {
        @media (min-width: 768px) {
          margin-right: 1rem;
          max-width:60px;
        }
      }
      h4{
        font-size:16px;
        font-weight: bold;
        color:#0072aa;
      }
      h6{
        color:#666;
        font-size:14px;
        font-weight: 100;
        max-width: 90%;
      }
      p,
      a {
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        bottom:0;
        opacity:0;
      }
      p:nth-child(1) {
        font-family: "Roboto";
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #43b1e7;
      }
      p:nth-child(2) {
        font-family: "Roboto Slab";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #6e7a8a;
      }
    }
    &--right {
      
      height: 100%;
      padding: var(--paddings);
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 3px 20px 0 rgba(7, 40, 80, 0.05);
      //background-image: linear-gradient(243deg, #0072aa 100%, #011c3e 0%);
      h4{
        color:#FF0006;
        font-size:22px;
        font-weight: bold;
      }

      h6{
        color:#aaa;
        font-size:16px;
        line-height:24px;
        strong {
          color:#666;
          display: block;
          margin-bottom:10px;
        }
      }
      .ann-item{
        position: relative;
       
      }
      @media (min-width: 1280px) {
        flex-direction: row;
      }
      img {
        @media (min-width: 768px) {
          margin-right: 2rem;
        }
      }
      p {
        text-align: center;

        @media (min-width: 768px) {
          text-align: initial;
        }
      }
       a {
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        bottom:0;
        opacity:0;
      }
      p:nth-child(1) {
        font-family: Roboto;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
      p:nth-child(2) {
        font-family: "Roboto Slab";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #ffffff;
      }
    }
  }
}
