.article {
  width: 100%;
  &--image {
    img {
      width: 100%;
      height: 280px;
      margin-bottom: 20px;
    }
  }
  &--content {
    p {
      font-family: "Roboto Slab";
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: justify;
    }
    p.author {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      color: #43b1e7;
      text-transform: capitalize;
      img {
        width: 38px;
        height: 38px;
        margin: 10px 10px 10px 0;
        padding: 9px;
        border-radius: 50%;
        box-shadow: 0 3px 20px 0 rgba(7, 40, 80, 0.05);
        background-image: linear-gradient(225deg, #0072aa 100%, #011c3e 0%);
      }
    }
    p.date {
      margin-top: 20px;
      margin-bottom: 40px;
    }
    p.heading {
      font-size: 18px;
      color: #011c3e;
      min-height: 48px;
      max-width: 80%;
    }
    p.summary,
    p.date {
      font-size: 14px;
      line-height: 1.57;
      color: #6e7a8a;
    }
    p.summary {
      background:#fff;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
     
    }
  }
  button {
    margin-top:2rem;
    margin-bottom: 2rem;
  }


}
