.footer {
  
  &-links {
    height: 100%;
    padding: var(--paddings);
    ul {
      column-count: 2;
      list-style: none;
      padding: 0px;
      line-height:48px;
      margin-bottom:3rem;
      li {
        a {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          color: #ffffff;
          &:hover{
            color:#011C3E;
          }
        }
      }
    }
    .copyright-text{
      font-weight:300;
      color:rgba(0,0,0,0.5);
    }
  }
  &-info {
    div.route-btn {
      padding:1rem 0;
      width:100%;
    }
    padding: var(--paddings);

    &>div {
      display: flex;
      align-items: center;
      margin-bottom: .4rem;
      img {
        margin-right: 1rem;
      }
    }
    h6 {
      font-family: 'Roboto';
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #43b1e7;
      margin-bottom: 20px;
    }
    p {
      font-family: 'Roboto Slab';
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #6e7a8a;
      margin-bottom: 0px;
    }
  }
}