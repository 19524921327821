.member-card {
    height: 350px;
    box-shadow: 0 10px 20px 0 rgba(7, 40, 80, 0.05);
    background-color: #ffffff;
    &--icon-side {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
           
                font-family: 'Roboto';
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                margin-bottom: 0px;
                color:#ba9a7a;
                
            
        }
        /*&::before{
            content: url('../../Assets/Images/businessman-svgrepo-com.svg');
            position: absolute;
            width: 150px;
            height: 150px;
            padding: 2rem;
            //box-shadow: 0 3px 20px 0 rgba(7, 40, 80, 0.1);
            border-radius: 50%;
            //background-image: linear-gradient(225deg, #0072aa 100%, #011c3e 0%);
        }*/
    }
    &--information-side {
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 4rem;
        position: relative;
        .qr-vcf-wrapper{
            position: absolute;
            width: 48px;
            height: 48px;
            right: 10%;
            top: 32%;    
            padding: 10px;
            border: 4px solid #ba9a7a;
            
            color: #333;
            img{
                width:100%;
            }
            
        }
        &_details {
            height: 95%;
            overflow: hidden;
            h1 {
                font-family: 'Roboto Slab';
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #011c3e;
            }
            h2,p {
                font-family: 'Roboto Slab';
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
            }
            h2 {
                color: #43b1e7;
            }
            p {
               color: #6e7a8a;
            }
            
        }
        a {
            font-size: 14px;
            position: absolute;
            bottom: 24px;
        }

    }

}
.member-name {
    padding: 2rem;
    background-color: #f0f4f9;
    p {
        font-family: 'Roboto';
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #43b1e7;
        margin-bottom: 0px;
        span {
            color:#ba9a7a;
        }
    }

}