.not-found {
  width: 100%;
  height: 50vw;
  display: inline-flex;
  align-items: center;
    background: rgb(0, 114, 170);
    min-height:100vh;
  background: linear-gradient(
    90deg,
    rgba(0, 114, 170, 1) 0%,
    rgba(0, 114, 170, 1) 0%,
    rgba(1, 28, 62, 1) 100%
  );
  
  &__wrapper{
    width:100%;
    text-align: center;
  }

  h1 {
    font-family: "Roboto Slab";
    color: white;
    font-weight: bold;
    font-size:2rem;
    width:100%;


    @media (min-width: 768px) {
      font-size: 3rem;
    }
    @media (min-width: 1024px) {
      font-size: 4rem;
    }
    @media (min-width: 1280px) {
      font-size: 8rem;
    }
  }

  h5 {
    width:100%;
    font-family: "Roboto Slab";
    color: white;
    font-weight: 300;
    font-size: 1rem;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
    @media (min-width: 1024px) {
      font-size: 2rem;
    }
    @media (min-width: 1280px) {
      font-size: 3rem;
    }
  }
  a {
  width: 100%;
    margin-top: 2rem;
    display: inline-block;
    outline:0;
  }
}
