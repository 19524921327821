
@import "../Fonts/stylesheet.css";

$color2: #cfe4ff;
$color1: #43b1e7;
$color3: #6e7a8a;
$color4: #2f4561;
$color5: #617d9f;
$color6: #011c3e;
$darknavy: #00142d;

@mixin text-shadow {
  text-shadow: 3px 0 20px black;
}

h1 {
  font-size: 2.5625rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.375rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

p {
  font-size: 1.125rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}




body {
  position: relative;
  font-weight: 200;
  color: #666;
  font-family: "Roboto Slab";
  min-height: 100vh;
  // Tüm sayfaların wrapper paddingleri.

  --paddings: 3rem;

  @media (min-width: 768px) {
    --paddings: 2rem;
  }
  @media (min-width: 1024px) {
    --paddings: 3rem;
  }
  @media (min-width: 1280px) {
    --paddings: 3.5rem;
  }
  @media (min-width: 1600px) {
    --paddings: 4rem;
  }


}
.App{
  height:100vh;
}

.loadingWrapper{
  position: absolute;
  width:100%;
  height:100%;
  background: rgb(0, 114, 170);
  background: linear-gradient(
    90deg,
    rgba(0, 114, 170, 1) 0%,
    rgba(0, 114, 170, 1) 0%,
    rgba(1, 28, 62, 1) 100%
  );
  &:hover{
    .loadingContentWrapper{
      .logoWrapper{
        .duplicatedLogoWrapper{
          //height:100%;
        }
      }
    }
  }
}

.loadingContentWrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.logoWrapper{
  width:64px;
  height:86px;
  position: relative;
}

.loadingBrandName{
  font-weight: 900;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin-top:10px;

}

.duplicatedLogoWrapper {
  position: absolute;
  width:100%;
  height:0%;
  top:0;
  overflow: hidden;
  transition: height 1s linear;
  animation-duration: 1s;
  animation-name: changeHeight;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changeHeight {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}



.bg-gradient {
  background: rgb(0, 114, 170);
  background: linear-gradient(
    90deg,
    rgba(0, 114, 170, 1) 0%,
    rgba(0, 114, 170, 1) 0%,
    rgba(1, 28, 62, 1) 100%
  );
}

.bg-gradient--brown {
  background: #DECBA4;
  background: -webkit-linear-gradient(to right, #ba9a7a, #DECBA4); 
  background: linear-gradient(to right, #ba9a7a, #DECBA4); 

}

.arslanbas {
  font-family: "Roboto";
  height: 100%;

  &-sidebar {
    width: 100%;
    z-index: 2;
    position: relative;
    top: 0px;
    left: 0px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    &--icon {
      text-align: left;
      padding: 2rem var(--paddings);
       @media (min-width: 768px) {
           padding: var(--paddings);
       }
      background-color: #00142d;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &--brandtext {
      display: none;
    }

    &--language-wrapper {
      position: relative;
      top:-2px;
      .language-prefix {
        font-weight: 900;
        color: #eee;
        padding: 4px 6px;
        cursor: pointer;
        &:hover {
          color: #ba9a7a;
        }
        &.active {
          color: #ba9a7a;
          &:hover {
            color: #43b1e7;
          }
        }
      }
      .language-prefix + .language-prefix + .language-prefix {
        border-right: 0px;
      }
    }
  }
  
  &-navigation {
    &-wrapper {
      position: fixed;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 99;
      left: 120px;
      transition: opacity linear 175ms;
      pointer-events: none;
      top: 0;
    }

    &-wrapper--opened {
      opacity: 1;
      pointer-events: all;
    }

    color: white !important;
    &--close {
      cursor: pointer;
      font-family: "Roboto Slab";
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      position: relative;
      top:30px;
      @media (min-width: 768px) {

        top: -100px;

      }
      &:before {
        position: absolute;
        background: #ba9a7a;
        width: 32px;
        height: 1px;
        content: "";
        bottom: -5px;
      }
      &:hover {
        color: #ba9a7a;
      }
    }
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0, 114, 170);
    background: linear-gradient(
      90deg,
      rgba(0, 114, 170, 1) 0%,
      rgba(1, 28, 62, 1) 0%,
      rgba(0, 114, 170, 0.7) 100%
    );
    right: 0;
    z-index: 3;
    ul {
      list-style-type: none;
      padding: 0px;
      //@include text-shadow;
      li,
      a {
        font-size: 24px;
        line-height: 52px;
              @media (min-width: 768px) {

                font-size: 32px;
                  line-height: 64px;

              }
      
        padding: 0px;
        font-weight: 800;
        color: white !important;
        text-decoration: none;
        &:hover {
          color: #ba9a7a !important;
        }
      }
      p {
        font-size: 18px;
      }
    }

    &--contact {
      p {
        font-size: 16px !important;
      }
    }
  }
  // &-subnav {
  //   color: white;
  //   padding-left: 1.6625rem;
  //   padding-bottom: 3rem;
  //   padding-top: 3rem;
  //   transition: 3s;
  //   h1 {
  //     padding-left: 1rem;
  //     max-width: 55%;

  //   }
  //   &--menu {
  //     transition: 3s;
  //     position: relative;
  //     height: max-content;
  //     margin-bottom: 4rem;
  //     z-index: 5;
  //     ul {
  //       padding-left: 0px;
  //       max-width: max-content;
  //       list-style-type: none;
  //       position: absolute;
  //       cursor: pointer;
  //       border-radius: 10px;

  //       li:not(li:first-child) {
  //         display: none;
  //       }
  //       &:hover {
  //         box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);

  //         li:not(li:first-child) {
  //           display: block;
  //         }
  //         li:first-child {
  //           border-bottom: 1px solid #dae3ee;
  //           color: #43b1e7;
  //         }
  //         li:nth-child(2){
  //           padding-top: 2rem;
  //         }
  //         li:last-child{
  //           padding-bottom: 2rem;
  //         }
  //         li {
  //           padding-top: 1rem;
  //           padding-bottom: 1rem;
  //           background-color: #fff;
  //         }
  //       }
  //       li {
  //         padding-top: 0.5rem;
  //         padding-bottom: 0.5rem;
  //         padding-right: 1rem;
  //         padding-left: 1rem;
  //         a {
  //           color: black !important;
  //           text-decoration: none;
  //           transition: color 0.225s ease;
  //           text-shadow: 0 0 2px lightgray;
  //           &:hover {
  //             color: #43b1e7 !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}


@media (min-width: 768px) {
  .arslanbas {
    &-navigation {
      width: calc(100% - 120px);
    }
    &-sidebar {
      width: 120px;
      position: fixed;
      height: 100%;
      overflow-x: hidden;
      &--icon {
        text-align: center;
        padding: 2.5rem 1.4rem;
        display: flex;
        justify-content: center;
      }
      &--language-wrapper {
        top:auto;
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
        padding-top: 20px;
        border-top: 1px solid #eee;
        .language-prefix {
          font-weight: 900;
          color: #eee;
          padding: 4px 6px;
          border-right: 1px solid #eee;
          cursor: pointer;
          &:hover {
            color: #ba9a7a;
          }
          &.active {
            color: #ba9a7a;
            &:hover {
              color: #43b1e7;
            }
          }
        }
        .language-prefix + .language-prefix + .language-prefix {
          border-right: 0px;
        }
      }
      &--brandtext {
        width: 100%;
        display: block;
        transform: scale(-1);
        writing-mode: vertical-rl;
        height: max-content;
        padding-top: 2rem;
        padding-bottom: 2rem;
        box-shadow: 25px 10px 10px 20px rgba(0, 0, 0, 0.2),
          0 10px 10px -10px rgba(0, 0, 0, 0.2);
        background: #011c3e;
        cursor: pointer;
        a {
          text-decoration: none;
          cursor: pointer;
        }
        .brand-text {
          font-weight: 300;
          color: #fff;
          text-decoration: none;
          padding: 0 1.5rem;
          cursor: pointer;
          font-family: Georgia;
          text-transform: uppercase;
          font-size:1.2rem;
            span {
              position:absolute;
              top:50%;
              transform:translateY(-50%);

            }
        }
        /*.logo {
          width: 64px;
          transform: translate(-50%, -50%);
          position: absolute;
          bottom: 0%;
          left: 50%;
          padding: 10px;
        } */
      }
    }
    &-content {
      margin-left: 120px;
    }
  }

  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-relative {
    position: relative !important;
  }
}
@media (min-width: 992px) {
  .border-lg-left {
    border-left: 1px solid #dee2e6 !important;
  }
  .arslanbas {
    &-navigation {
      position: relative;
      z-index: 99;
      &--links:hover .arslanbas-navigation--links__sublinks {
        display: flex !important;
        // flex-wrap: wrap;
        flex-direction: column;
        margin: 0;
        position: absolute;
        top: 0px;
        left: 30%;
        max-height: 100%;
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        //background:#ba9a7a;
        // height: 100%;
        overflow-y: scroll;
        border-left: 1px solid rgba(#fff, 0.1);
        li {
          flex: 1 0 25%;
          margin-right: 20px;
        }

        a {
          font-weight: 300;
          font-family: "Roboto Slab";
          font-size: 24px;
        }
      }
    }

  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
}
@media (min-width: 1200px) {
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
}

.menu-icon-wrapper {
  width: 32px;
  display: inline-block;
  @media (min-width: 768px) {
    width: 60%;
  }

  span {
    display: block;
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    height: 2px;
    background: #fff;
    transition: all 0.3s;
    position: relative;
  }

  span + span {
    margin-top: 8px;
  }

  .active span:nth-child(1) {
    animation: ease 0.3s top forwards;
  }

  .not-active span:nth-child(1) {
    animation: ease 0.3s top-2 forwards;
  }

  .active span:nth-child(2) {
    animation: ease 0.3s scaled forwards;
  }

  .not-active span:nth-child(2) {
    animation: ease 0.3s scaled-2 forwards;
  }

  .active span:nth-child(3) {
    animation: ease 0.3s bottom forwards;
  }

  .not-active span:nth-child(3) {
    animation: ease 0.3s bottom-2 forwards;
  }

  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 11px;
      transform: rotate(0);
    }
    100% {
      top: 11px;
      transform: rotate(45deg);
    }
  }

  @keyframes top-2 {
    0% {
      top: 11px;
      transform: rotate(45deg);
    }
    50% {
      top: 11px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }

  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 11px;
      transform: rotate(0);
    }
    100% {
      bottom: 11px;
      transform: rotate(135deg);
    }
  }

  @keyframes bottom-2 {
    0% {
      bottom: 11px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 11px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
