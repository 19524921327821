a {
    text-decoration: none !important;

    .activity-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        h4 {
            font-family: 'Roboto';
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #43b1e7;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        p {
            font-family: 'Roboto Slab';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #ffffff;
            text-align:justify;
          
        }
        cursor: pointer;
    
    }
}
